<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card">
                    <div class="card-header card-header-divider">Default Alerts</div>
                    <div class="card-body">
                        <div class="alert alert-success alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"><span class="mdi mdi-check"></span></div>
                            <div class="message"><strong>Good!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                        <div class="alert alert-info alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"><span class="mdi mdi-info-outline"></span></div>
                            <div class="message"><strong>Info!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                        <div class="alert alert-warning alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"><span class="mdi mdi-alert-triangle"></span></div>
                            <div class="message"><strong>Warning!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                        <div class="alert alert-danger alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"> <span class="mdi mdi-close-circle-o"></span></div>
                            <div class="message"><strong>Danger!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                        <div class="alert alert-primary alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"><span class="mdi mdi-notifications"></span></div>
                            <div class="message"><strong>Primary!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                        <div class="alert alert-dark alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"><span class="mdi mdi-notifications"></span></div>
                            <div class="message"><strong>Dark!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                        <div class="alert alert-light alert-dismissible" role="alert">
                            <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span class="mdi mdi-close" aria-hidden="true"></span></button>
                            <div class="icon"><span class="mdi mdi-notifications"></span></div>
                            <div class="message"><strong>Light!</strong> Better check yourself, you're not looking too good.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Alerts',
    }
</script>
